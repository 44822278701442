<template>


  <transition name="slide">

  <div class="view-trip">
   <main-nav />
    <main-titlebar myclass="purpleBar" :showback="showBack"/>
    <div class="trip-view-wrapper">
         <div v-for="trip in trips" :key="trip.id" class="trip-view-row2">
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-clock"></i>  {{formatDateTime(trip.etd)}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.origin_address}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.destination_address}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col2"><i class="fa-solid fa-people-group"></i> {{trip.passengers}}</div>
              <div class="trip-view-col2">  {{ formatCurrency(trip.price)}}</div>
            </div>

            <div class="trip-view-row">
              <div class="trip-view-col2">Aangeboden door</div>
              <div class="trip-view-col2">  {{ trip.provider_name}}</div>
            </div>


            <Form @submit="acceptTrip" :validation-schema="schema">
          
            <Field name="id" id="id" v-model="trip.id"  type="hidden"/>
            <Field name="gpsloc" id="gpsloc" v-model="curLatLon"  type="hidden"/>


            
       


            <!-- Terms -->
            <div class="form-group">
              <div class="col-25 ">
                <Field id="acceptterms" name="acceptterms" type="checkbox" class="form-input" v-model="acceptterms" value="at" />

              </div>
              <div class="col-75 ">
                <div class="form-label textAlignLeft">
                  <label for="acceptterms">Accepteer voorwaarden</label>
                </div>                
              </div>
            </div>
            <div class="form-group">
              <ErrorMessage name="acceptterms" class="error-feedback" />
            </div>

            <!-- Controls -->

            <div class="trip-view-row">
              <div class="trip-view-col">
                <button class="default-button" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Rit aannemen</span>
                </button>
              </div>
            </div>



  

          </Form>
         
          </div>
    </div>
  </div>

    </transition>

</template>
<script>

import TripService from "../../services/trip-service";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name:'settings',
  data() {
    var status = 'loading'
    var trips;
    var commission;
    var acceptterms = false;
    var markers = [];
    var showBack = true;
    const schema = yup.object().shape({
      // commission: yup.string().required("Commission is required"),
      acceptterms: yup.string() // use bool instead of boolean
        .oneOf(["at"], "You must accept the terms and conditions"),
      // destination_address: yup.string().required("Destination is required")
    });
    return { 
      curGPSpos:this.currentLocation(),
      trips, 
      loading: false,
      message: "",
      status, 
      markers,
      schema, 
      commission,
      acceptterms,
      showBack }
  },
  methods: {
    acceptTrip(trip) {
      // return id;
      TripService.acceptTrip(trip).then(
        (response) => {
          this.status = response.status;
          // this.trips = response.data;
          console.log("Trip accpeted: " + JSON.stringify(this.trips));
          this.$router.push('/trips/view/'+trip.id);        
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  
    },
    getCBVal() {
      alert(' getCBVal:'+ this.acceptterms)
    }
    
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    TripService.getTrip(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;
        targ.trips = response.data;
        console.log("data loaded from: " + JSON.stringify(targ.trips));
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  computed: {
    isPublic() {
      return this.trips[0].public;
    },
    isOwner() {
      var r = false;
      var tripOwner = this.trips[0]["owner"]
      var user = JSON.parse(localStorage.getItem('user')).userid;
      // var user  = this.$store.state.auth.user.userid;
      console.log("isOwner: " + tripOwner + " | userid:" + user);
      if(user == tripOwner) {
        r = true;
      }
      return r;
    },
    curLatLon() {
      var ll = this.curGPSpos.lat + "," + this.curGPSpos.lng; 
      return ll;
    }
  },
  
  components: {
    Form,
    Field,
    ErrorMessage,
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  }
}
</script>